import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Cursor from "../components/Cursor";
import { useEffect, useLayoutEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const SharedLayout = () => {

    const { pathname } = useLocation();

    // Scroll to top on route change
    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });

        // Refresh ScrollTrigger after images have loaded
        document.querySelectorAll("img").forEach(img => {
            if (img.complete) {
                ScrollTrigger.refresh();
            } else {
                img.addEventListener('load', imgLoaded => ScrollTrigger.refresh());
            }
        });

    }, [pathname]);

    const main = useRef();

    useLayoutEffect(() => {

        const ctx = gsap.context((self) => {
            const logo = document.querySelector('.site-header__logo');

            if (logo) {

                const logoTl = gsap.timeline();

                logoTl.to(logo, {
                    scrollTrigger: {
                        trigger: ".download",
                        start: 'top top+=5%',
                        end: '+=5%',
                        scrub: true
                    },
                    filter: 'invert(1)'
                })
            }

        }, main); // <- Scope!

        return () => {
            ctx.revert();
            setTimeout(() => {
                ScrollTrigger.refresh();
            }, 300);
        } // <- Cleanup!

    }, [pathname])

    return (
        <div ref={main}>
            <Cursor />
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
};

export default SharedLayout;