import { NavLink } from 'react-router-dom'
import logo from '../assets/logos/logo.svg';

function Header() {

    return (
        <>
            <header className="site-header">
                <div className="site-header__logo">
                    <NavLink to="/">
                        <img src={logo} alt="Tegernsee Logo" />
                    </NavLink>
                </div>
            </header>
        </>
    )
}

export default Header