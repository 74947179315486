import { useAppContext } from '../context/appContext';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children }) {
    const { user, userLoading } = useAppContext();

    if (userLoading) {
        return (
            <div className="preloader" style={{ display: 'grid', placeItems: 'center' }}>
                <span style={{ textTransform: 'uppercase' }}>Loading...</span>
            </div>
        )
    }

    if (!user) {
        return <Navigate to="/login" />
    }
    return children;
}

export default ProtectedRoute