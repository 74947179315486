import Container from '../components/layout/Container';
import Section from '../components/layout/Section';
import Gallery from '../components/Gallery';
import VideoOverlay from '../components/VideoOverlay';

import heroVideoMp4 from '../assets/videos/Tegernsee_Preview.mp4';
import heroVideoWebm from '../assets/videos/Tegernsee_Preview.webm';

import originalVideoMp4 from '../assets/videos/230626_QS_Colliers_WH_1.mp4';
import posterJpg from '../assets/images/poster.jpg';

import { useLayoutEffect, useRef, useState } from 'react';
import { gsap } from "gsap";
import { useLocation } from 'react-router-dom';
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Lottie from "lottie-react";
import mouseScrolling from "../assets/lotties/98310-scrolldown-white.json";

import pdf from '../assets/downloads/Seehotel_Waltershof_IM.pdf';

function Home() {

    const main = useRef();
    const videoRef = useRef();

    const [isVisible, setIsVisible] = useState();

    const galleryRef = useRef(null);

    const { pathname } = useLocation();

    useLayoutEffect(() => {

        const ctx = gsap.context((self) => {
            const hero = document.querySelector('.hero');

            if (hero) {
                const heroVideo = hero.querySelector('.hero__video');

                const heroTl = gsap.timeline();

                heroTl.to(heroVideo, {
                    scrollTrigger: {
                        trigger: hero,
                        start: 'top top',
                        end: 'bottom top',
                        scrub: true
                    },
                    y: '50vh'
                })

                const heroIcon = document.querySelector('.hero__icon');

                if (heroIcon) {

                    const heroIconTl = gsap.timeline();

                    heroIconTl.to(heroIcon, {
                        scrollTrigger: {
                            trigger: heroIcon,
                            start: 'top bottom-=10%',
                            end: '+=20%',
                            scrub: true
                        },
                        opacity: 0
                    })
                }
            }

            const download = document.querySelector('.download');
            const downloadContainer = document.querySelector('.download__container');

            if (download) {
                gsap.timeline({
                    scrollTrigger: {
                        trigger: download,
                        start: 'top top',
                        end: () => `+=${download.clientHeight}px`,
                        pin: downloadContainer,
                        pinSpacing: true,
                    }
                });
            }

        }, main); // <- Scope!

        return () => {
            ctx.revert();
            setTimeout(() => {
                ScrollTrigger.refresh();
            }, 300);
        } // <- Cleanup!

    }, [pathname])

    function handleClick() {
        setIsVisible(!isVisible);
    }

    return (
        <main className="site-main home-page" ref={main}>
            <Section className="hero">
                <video className="hero__video" playsInline autoPlay muted loop>
                    <source src={heroVideoMp4} type="video/mp4" media="all and (max-width: 480px)" />
                    <source src={heroVideoWebm} type="video/webm" media="all and (max-width: 480px)" />
                    <source src={heroVideoMp4} type="video/mp4" />
                    <source src={heroVideoWebm} type="video/webm" />
                </video>
                <Lottie className="hero__icon" animationData={mouseScrolling} loop={true} />
                <h3 className="hero__title interactable" data-type='play' onClick={() => handleClick()}>
                    <span>watch</span><br />
                    THE MOVIE
                </h3>
                <VideoOverlay originalVideoMp4={originalVideoMp4} poster={posterJpg} innerRef={videoRef} isVisible={isVisible} />
            </Section>
            <Section className="download">
                <Gallery innerRef={galleryRef} />
                <Container className="download__container">
                    <a href={pdf} className="download-item interactable" data-type='download'>
                        <h3 className="download-item__title">
                            <span>download</span><br />
                            PDF
                        </h3>
                    </a>
                </Container>
            </Section>
        </main>
    )
}

export default Home