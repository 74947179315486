import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { useAppContext } from '../context/appContext'
import Container from '../components/layout/Container'
import logo from '../assets/logos/logo.svg'

import colliersLogo from '../assets/logos/colliers-white.svg'
import bgImage from '../assets/images/bgImage.jpg'

const initialState = {
    name: '',
    password: ''
}

const Login = () => {
    const navigate = useNavigate();
    const [values, setValues] = useState(initialState);
    const { user, isLoading, showAlert, alertType, alertText, displayAlert, loginUser } = useAppContext();

    const handleChange = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const { name, password } = values
        if (!password) {
            displayAlert()
            return
        }
        const currentUser = { name, password }
        loginUser(currentUser)
    }

    useEffect(() => {
        if (user) {
            setTimeout(() => {
                navigate('/');
            }, 1500)
        }
    }, [user, navigate]);

    // Overriding nested component styles
    // sx={{ '& .MuiInputBase-root': { borderRadius: '0px' } }}

    const inputColor = '#fff'
    const inputStyle = {
        '& label.Mui-focused': {
            color: inputColor,
        },
        '& .MuiInputBase-root': {
            borderRadius: '0px',
            backgroundColor: 'transparent',
            backdropFilter: 'blur(5px)',
            '& .Mui-focused': {
                color: inputColor,
            }
        },
        '& .MuiInputBase-input': {
            '&::placeholder': {
                color: inputColor,
            }
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: inputColor,
            },
            '&:hover fieldset': {
                borderColor: inputColor,
            },
            '&.Mui-focused fieldset': {
                borderColor: inputColor,
            },
        },
    }

    const buttonColor = '#fff'
    const buttonStyle = {
        border: `1px solid ${buttonColor}`,
        color: buttonColor,
        borderRadius: '0px',
        backgroundColor: 'transparent',
        backdropFilter: 'blur(5px)',
        fontFamily: 'var(--ff-regular)',
        '&:hover': {
            border: `1px solid ${buttonColor}`,
            color: '#fff',
        }
    }

    return (
        <main className="site-main login-page"
            style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover' }}
        >
            <Container>
                <div className="card">
                    <div className="card__logo">
                        <img src={logo} alt="Seehotel Waltershof Rottach-Egern Logo" />
                    </div>
                    <div className="card__subline">
                        Seehotel Waltershof<br />
                        Rottach-Egern<br />
                        <span>Tegernsee</span>
                    </div>
                    <form className="card__form" onSubmit={handleSubmit}>
                        <TextField sx={inputStyle} InputLabelProps={{
                            sx: {
                                color: "#fff",
                                fontFamily: 'var(--ff-regular)'
                            }
                        }} className="card__input" size="small" error={alertType === "danger"} id="outline-basic password" label="Password" variant="outlined" type="password" name="password" helperText={showAlert ? alertText : ""} onChange={handleChange} />
                        <Button sx={buttonStyle} className="card__button" size="large" type="submit" disabled={isLoading} variant="outlined" disableElevation>Login</Button>
                    </form>
                </div>
                <div className="footer-logo">
                    <img src={colliersLogo} alt="logo colliers" />
                </div>
            </Container>
        </main>
    )
};

export default Login