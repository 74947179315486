import React, { useReducer, useContext, useEffect } from 'react';
import axios from 'axios';
import reducer from './reducer';

import { DISPLAY_ALERT, CLEAR_ALERT, LOGIN_USER_BEGIN, LOGIN_USER_SUCCESS, LOGIN_USER_ERROR, LOGOUT_USER, GET_CURRENT_USER_BEGIN, GET_CURRENT_USER_SUCCESS } from "./actions";

const user = localStorage.getItem('user')
const token = localStorage.getItem('token')

const initialState = {
    userLoading: true,
    isLoading: false,
    showAlert: false,
    alertText: '',
    alertType: '',
    user: user ? JSON.parse(user) : null,
    token: token
}

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    // axios
    const authFetch = axios.create({
        baseURL: '/api/v1',
        headers: {
            Authorization: `Bearer ${state.token}`,
        },
    });

    // response
    authFetch.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response.status === 401) {
                logoutUser();
            }
            return Promise.reject(error);
        }
    );

    const displayAlert = () => {
        dispatch({
            type: DISPLAY_ALERT
        })
        clearAlert()
    }

    const clearAlert = () => {
        setTimeout(() => {
            dispatch({
                type: CLEAR_ALERT
            })
        }, 5000)
    }

    const addUserToLocalStorage = ({ user, token }) => {
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('token', token)
    }

    const removeUserToLocalStorage = () => {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
    }

    const loginUser = async (currentUser) => {
        dispatch({
            type: LOGIN_USER_BEGIN
        })
        try {
            const { data } = await axios.post('/api/v1/auth/login', currentUser)
            const { user, token } = data

            dispatch({
                type: LOGIN_USER_SUCCESS,
                payload: { user, token }
            })

            addUserToLocalStorage({ user, token })

        } catch (error) {
            dispatch({
                type: LOGIN_USER_ERROR,
                payload: { msg: error.response.data.msg }
            })
        }

        clearAlert()
    }

    const logoutUser = () => {
        dispatch({
            type: LOGOUT_USER
        })
        removeUserToLocalStorage()
    }

    const getCurrentUser = async () => {

        dispatch({ type: GET_CURRENT_USER_BEGIN });
        try {
            const { data } = await authFetch('/auth/getCurrentUser');
            const { user } = data;

            dispatch({
                type: GET_CURRENT_USER_SUCCESS,
                payload: { user },
            });
        } catch (error) {
            if (error.response.status === 401) return;
            logoutUser();
        }
    };

    useEffect(() => {
        getCurrentUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppContext.Provider value={{ ...state, displayAlert, loginUser, logoutUser }}>
            {children}
        </AppContext.Provider>
    )
}

const useAppContext = () => {
    return useContext(AppContext);
}

export { AppProvider, initialState, useAppContext };