import { Link } from 'react-router-dom'
import { useAppContext } from '../context/appContext';
import Container from './layout/Container';
import colliersLogo from '../assets/logos/colliers-black.svg';

function Footer() {
    const { logoutUser } = useAppContext();

    return (
        <footer className="site-footer">
            <Container className="site-footer__container">
                <div className="site-footer__logo">
                    <img src={colliersLogo} alt="Colliers Logo" loading='lazy' />
                </div>
                <div className="site-footer__navigation">
                    <ul>
                        <li><a href="https://www.colliers.de/impressum/" target="_blank" rel="noreferrer">Impressum</a></li>
                        <li><Link onClick={logoutUser}>Logout</Link></li>
                    </ul>
                </div>
            </Container>
        </footer>
    )
}

export default Footer