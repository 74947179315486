function VideoOverlay({ isVisible, originalVideoMp4, poster, innerRef }) {

    return (
        <>
            {
                isVisible &&
                <div className="video-overlay" ref={innerRef}>
                    <video className="video-overlay__video" poster={poster} autoPlay playsInline controls>
                        <source src={originalVideoMp4} type="video/mp4" />
                    </video>
                </div>
            }
        </>

    )
}

export default VideoOverlay