import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import './styles/main.scss';

import Lenis from '@studio-freight/lenis'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// LOGIN - ERROR PAGE
import Login from './pages/Login';
import Error from './pages/Error';

// GERMAN PAGES
import SharedLayout from './pages/SharedLayout';
import ProtectedRoute from './pages/ProtectedRoute';

import Home from './pages/Home';

const App = () => {
    const location = useLocation();

    useEffect(() => {
        const lenis = new Lenis()

        lenis.on('scroll', ScrollTrigger.update)

        gsap.ticker.add((time) => {
            lenis.raf(time * 1000)
        })

        function raf(time) {
            lenis.raf(time)
            requestAnimationFrame(raf)
        }

        requestAnimationFrame(raf)

    }, [])

    return (
        <>
            <Routes location={location}>

                <Route path="/" element={<ProtectedRoute><SharedLayout /></ProtectedRoute>}>
                    <Route index element={<Home />} />
                </Route>

                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Navigate to="/" />} />
                <Route path="*" element={<Error />} />

            </Routes>
        </>
    );
}

export default App;