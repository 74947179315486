import image01 from '../assets/images/image01.jpg';
import image02 from '../assets/images/image02.jpg';
import image03 from '../assets/images/image03.jpg';
import image04 from '../assets/images/image04.jpg';
import image05 from '../assets/images/image05.jpg';
import image06 from '../assets/images/image06.jpg';

function Gallery({ innerRef }) {

    return (
        <div className="gallery" ref={innerRef}>
            <div className="gallery__item">
                <img src={image01} alt="" />
            </div>
            <div className="gallery__item">
                <img src={image02} alt="" />
            </div>
            <div className="gallery__item">
                <img src={image03} alt="" />
            </div>
            <div className="gallery__item">
                <img src={image04} alt="" />
            </div>
            <div className="gallery__item">
                <img src={image05} alt="" />
            </div>
            <div className="gallery__item">
                <img src={image06} alt="" />
            </div>
        </div>
    )
}

export default Gallery