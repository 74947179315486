import { useEffect, useRef } from "react"
import cursorDownload from '../assets/icons/cursor-download.svg'
import cursorPlay from '../assets/icons/cursor-play.svg'
function Cursor() {

    const cursorRef = useRef();
    const cursorIconRef = useRef();

    useEffect(() => {

        if (cursorRef.current) {
            window.addEventListener('mousemove', (e) => {
                cursorRef.current.style.top = e.y + 'px';
                cursorRef.current.style.left = e.x + 'px';
            }, false);

            window.addEventListener('mousemove', (e) => {
                const interactable = e.target.closest('.interactable')
                if (interactable) {
                    let type = interactable.getAttribute('data-type');
                    switch (type) {
                        case 'download':
                            cursorIconRef.current.src = cursorDownload;
                            break;
                        case 'play':
                            cursorIconRef.current.src = cursorPlay;
                            break;
                        default:
                            break;
                    }
                    e.target.style.cursor = 'none';
                    cursorRef.current.classList.add('cursor-grow');
                } else {
                    cursorRef.current.classList.remove('cursor-grow');
                }
            })
        }

    }, []);

    return (
        <div className="cursor" ref={cursorRef}>
            <div className="cursor__inner">
                <img ref={cursorIconRef} src={cursorPlay} alt="" />
            </div>
        </div>
    )
}

export default Cursor